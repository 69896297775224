.login {
  background-color: #000000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.login .login-column {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 300px 1fr 100px;
  gap: 0px 0px;
  grid-template-areas: "." "." ".";
  height: 100%;
  justify-items: center;
  padding-bottom: 30px;
}
.login .login-column .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.login .login-column .logo .hero {
  font-weight: 100;
  color: #959595;
  display: flex;
  gap: 13px;
  font-size: 25px;
  margin-top: 12px;
}
.login .login-column .login-contnent {
  display: flex;
  align-items: flex-end;
}
.login .login-column .login-contnent .btn-login[disabled] {
  background-color: #b9b9b9 !important;
}
.login .login-column .login-contnent .btn-login {
  background-color: #fff300;
  font-size: 16px;
  border: 0;
  width: 200px;
  height: 43px;
  color: #253544;
  margin: auto;
}
.login .login-column .login-contnent .btn-login:hover {
  background-color: #fff64c;
}

.footer-title {
  color: #787878;
  font-size: 12px;
  letter-spacing: 0.3px;
}/*# sourceMappingURL=login.css.map */