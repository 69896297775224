.noneImg{
  width: 30px;
  height: 30px;
  background-color: #dfdfdf;
  color: #212227;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: bold;
}
.progres-bar{
  background: linear-gradient(270deg, #479a70 0%,#479a70 20%, #d8b744 40%, #d8b744 60%, #d04140 80%, #d04140 100%);
  width: 100px;
  height: 5px;
  border-radius: 10px;
  position: relative;
  .point{
    position: absolute;
    background-color: #d9d9d9;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
    width: 10px;
    height: 10px;
    border-radius: 100px;
    top: -3px;
  }
}
.procent{
  width: 40px;
  margin-left: 10px;
  text-align: right;
}

.token-names{
  display: flex;
  flex-direction: column;
  line-height: 18px;
  .name-row{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3px;
  }
  .ticker-row{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3px;
    .ticker{
      font-size: 10px;
      color: rgb(167, 173, 184);
      font-weight: bold;
      padding-right: 5px;
    }
  }
}
.table-responsive{
  th {
    font-weight: 600;
    font-size: 12px;
  }
  tr{
    td{
      padding-top: 9px;
      padding-bottom: 9px;
      font-size: 12px;
      & > div:first-child{
        height: 28px;
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
  }
  tr:nth-child(odd) {
    td{
      background: #67676703
    }
  }
}
.index-zero{
  position: relative;
  top: 2px;
  font-size: 8px;
  padding: 0 2px 0 1px;
  font-weight: 600;
}
.to-right{
  justify-content: flex-end;
}
.complete{
  display: flex;
  div{
    width: 100%;
    text-align: center;
  }
}
.btns{
  gap: 5px;
  .table-btn{
    border: 0;
    background-color: #212227;
    color: #fff;
    cursor: pointer;
    padding: 2px 10px;

    &:hover{
      background-color: #1f2025;
    }
  }
}
.bonding-complete {
  background-color: #2d9863;
  color: #fff;
  font-weight: 500;
  padding: 3px 0;
}
.custom-modal-width {
  max-width: 400px; /* Ustaw szerokość modala na 500px */
}

.header-title{
  text-align: right;
  display: flex;
  line-height: 1.2;
  position: unset;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  transition: all ease .1s;
  
  &:hover{
    opacity: .7;
  }
  .small{
    font-size: 10px;
    color: rgb(167, 173, 184);
    font-weight: bold;
  }
}

.settings{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  right: 18px;
  top: 21px;
  gap: 10px;
  .refresh{
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    align-items: center;
    .labelBTN{
      opacity: 0.4;
      font-size: 11px;
      padding: 0;
      border: 0;
      background: transparent;
    }
    .refresh-value{
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
  .filter{
    display: flex;
    gap: 10px;
    .filter-search{
      height: 25px;
      font-size: 11px;
      color: #fff !important;
      background: transparent;
      border: 1px solid #303032;
      text-align: center;
      padding: 0 8px;
      &::placeholder{
        color: #c6c6c6;
      }
    }
  }
}

.container-fluid{
  position: relative;
}

.btnSimple{
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #2d9863;
}
.column-header-row{
  display: flex;
  gap: 2px;
  justify-content: flex-end;
}
.sort-desc,
.sort-asc{
  color: #2d9863 !important;
}
.sortArrow{
  min-width: 2px;
}